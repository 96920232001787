<template>
  <div class="calendarArea fill-height">
    <v-dialog width="500" v-model="showIndebitModal">
      <v-card>
        <v-card-title class=" font-weight-bold" style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)">
          کاربر بدهکار
        </v-card-title>
        <v-card-text class="">
          <h3 style="line-height: 30pt">
            این کاربر پکیج کافی برای برگزاری این جلسه را ندارد.آیا میخواهید این کاربر را بدهکار کنید?
          </h3>
          <div class="mt-4 d-flex justify-end">
            <v-btn @click="showIndebitModal = false" color="red white--text" elevation="0">
              لغو
            </v-btn>
            <v-btn color="primary" elevation="0" @click="indebitUserAndReservetime" class="mr-3">
              بدهکار کن
            </v-btn>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="setSessionModal" width="500px" scrollable>
      <v-card>
        <v-card-title>
          تنظیم‌جلسه
        </v-card-title>
        <v-card-text>
          <v-form ref="setSessionForm">
            <template v-if="selectedTimeForAssignIds.length == 0">
              <div>
                <v-text-field :rules="requiredRules" clearable outlined v-model="bulkTime.start_at"
                              readonly
                              @click="openClock('start_bulk')" dense
                              label="ساعت شروع"></v-text-field>
              </div>

              <div class="mx-2">
                <div>
                  <div class="d-flex mt-2 mb-3">
                    <p class="font-weight-bold ml-2">
                      زمان جلسه:
                    </p>
                    <v-chip class="mx-2" small v-for="(duration , i) in durations" :key="i"
                            @click="attach_duration = duration.key"
                            :color="attach_duration == duration.key ? 'primary' : ''">
                      {{ duration.title }}
                    </v-chip>
                    <p>
                      دقیقه
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <v-text-field
                    :rules="requiredRules"
                    outlined
                    dense
                    :value="session_date"
                    label="تاریخ جلسه"
                    prepend-icon="event"
                    readonly
                    @click="session_date_modal = true"
                ></v-text-field>
                <AdminDatePicker @close="session_date_modal=false"
                                 :dialog="session_date_modal"
                                 @input="session_date_modal = false"
                                 v-model="session_date"
                ></AdminDatePicker>
              </div>
            </template>
            <div style="position: relative">
              <div class="d-flex  justify-center" style="margin-top: -25px">
                <div class="mt-5">
                  نام کاربر
                </div>
                <div class="mx-2">
                  <v-switch dense inset v-model="is_user_id"/>
                </div>
                <div class="mt-5">
                  کد کاربر
                </div>
              </div>
              <v-text-field :rules="requiredRules" outlined @input="userId = null" dense label="نام مراجع"
                            v-model="username"></v-text-field>
              <div class="userDropDown" v-if="users.length != 0">
                <div class="user-row">
                  <div class="user-record" v-for="(user , i) in users" :key="i" @click="selectUser(user)">
                    <div class="d-flex justify-space-between " style="border-bottom: 1px solid #ddd">
                      <div>
                        {{ user.full_name }}
                        -
                        <v-chip v-if="user.leadUser" x-small color="primary pt-1">lead</v-chip>
                        <v-chip x-small v-if="user.newUser" color="red white--text mr-1 pt-1">new</v-chip>
                        <div class="mt-1 ">
                          {{ user.email }}
                        </div>

                      </div>
                      <div>
                        <v-chip color="primary" small v-if="user.notes.length != 0">
                          {{ user.notes[0].description }}
                        </v-chip>
                        <div style="direction: ltr !important;text-align: left;" class="mt-1 ml-1">
                          {{ user.cel }}
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div class="boxWalletStatus" v-if="selectedUser">
              <div class="font-weight-bold mb-2">
                وضعیت کیف پول کاربر
              </div>
              <div class="d-flex">
                <div class="walletItem">
                  <div class="ItemTitle">
                    تعداد جلسات:
                  </div>
                  <div class="itemValue">
                    {{ selectedUser.wallet.global_packages }}
                  </div>
                </div>
                <div class="walletItem mr-4">
                  <div class="ItemTitle">
                    مدت زمان کسر/اضافه:
                  </div>
                  <div class="itemValue">
                    {{ selectedUser.time_wallet.time }}
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div>
                  <v-btn color="primary" x-small elevation="0" @click="resolveUserSession">
                    تسویه
                  </v-btn>
                </div>
              </div>
            </div>
            <div>
              <div>
                <v-checkbox class="pa-0 ma-0" v-model="userIndebt" label="کاربر بدهکار شود؟"></v-checkbox>
                <v-checkbox class="pa-0 ma-0" v-model="useUserCreditor" label="استفاده از طلب کاربر"></v-checkbox>
                <v-checkbox class="pa-0 ma-0" v-model="userDidntRegister" v-if="selectedTimeForAssignIds.length != 0"
                            label="کاربر عضو سیستم نیست؟"></v-checkbox>
                <v-checkbox class="pa-0 ma-0" v-model="reservedNextTime"
                            v-if="!userDidntRegister &&  !isPreSession"
                            label="رزرو کردن زمان‌های بعدی برای کاربر"></v-checkbox>
                <v-fade-transition>
                  <div v-if="reservedNextTime">
                    <div class="mb-5">
                      <v-form ref="reserved_time">
                        <v-row>
                          <v-col cols="12" lg="5">
                            <v-text-field label="تعداد جلسات" :rules="requiredRules" v-model="reserved_model.count"
                                          dense outlined></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="5">
                            <v-select :items="reserved_units" item-value="key" :rules="requiredRules" item-text="title"
                                      label="بازه زمانی" v-model="reserved_model.unit" dense outlined></v-select>
                          </v-col>
                          <v-col cols="12" lg="2">
                            <v-btn color="primary" block class="mt-1" elevation="0" @click="prepareReservedTime">
                              تایید
                            </v-btn>
                          </v-col>
                        </v-row>
                        <div>
                          <!--                          <v-row>-->
                          <!--                            <v-col cols="6" v-for="(item , i) in reserved_prepare_time" :key="i">-->
                          <!--                              <div class="reservedTimeBox">-->
                          <!--                                <div class="d-flex justify-space-between">-->
                          <!--                                  <div>-->
                          <!--                                    {{ item.date | dateJalaliFilter }}-->
                          <!--                                    <br>-->
                          <!--                                    {{ item.date }}-->
                          <!--                                  </div>-->
                          <!--                                  <div class="d-flex flex-column justify-center" style="font-size: 13px">-->
                          <!--                                    {{ item.start }} تا {{ item.end }}-->
                          <!--                                  </div>-->
                          <!--                                </div>-->
                          <!--                              </div>-->
                          <!--                            </v-col>-->
                          <!--                            <v-col cols="6">-->
                          <!--                            </v-col>-->
                          <!--                          </v-row>-->
                          <div class="reserveTimeArea">
                            <v-data-table
                                disable-pagination
                                hide-default-footer
                                hide-default-header
                                :items="reserved_prepare_time"
                                :headers="headers"
                                dense
                                show-select

                            >
                              <!--                            <template v-slot:top>-->
                              <!--                              <v-switch-->
                              <!--                                  v-model="singleSelect"-->
                              <!--                                  label="Single select"-->
                              <!--                                  class="pa-3"-->
                              <!--                              ></v-switch>-->
                              <!--                            </template>-->
                              <template v-slot:header="{ props: { headers } }">
                                <tr>
                                  <th class="pa-1">
                                    <v-checkbox v-model="selectedAllTime" :value="true" dense multiple></v-checkbox>
                                  </th>
                                  <th class=" text-center">شمسی</th>
                                  <th class=" text-center">میلادی</th>

                                  <!--                                <td class=" text-center">{{ item.end }} - {{ item.start }}</td>-->
                                  <th class=" text-center">
                                    وضعیت
                                  </th>
                                </tr>
                              </template>
                              <template v-slot:item="{item}">
                                <tr :style="!item.visibility && 'background: rgba(255,0,0,0.2);color:black'">
                                  <td class="pa-1">
                                    <v-checkbox :readonly="!item.visibility" class="pa-0 mt-3 ma-0" :value="item" dense
                                                multiple
                                                v-model="selected_reserved_session">
                                    </v-checkbox>
                                  </td>
                                  <td class="pa-1 text-center">{{ item.date | dateJalaliFilter }}</td>
                                  <td class="pa-1 text-center">{{ item.date }}</td>

                                  <!--                                <td class="pa-1">{{ item.end }} - {{ item.start }}</td>-->
                                  <td class="pa-1 text-center">
                                    <template v-if="item.confilict_time">
                                      <template v-if="item.confilict_time.state == 'off_time'">
                                        <v-chip x-small elevation="0" color="red white--text" class="font-weight-bold">
                                          تعطیل
                                        </v-chip>
                                      </template>
                                      <template v-else>
                                        <v-chip x-small elevation="0" color="warning white--text"
                                                class="font-weight-bold">
                                          تداخل زمان
                                        </v-chip>
                                      </template>
                                    </template>
                                    <template v-else>
                                      <template v-if="item.description.includes('تخصیص')">
                                        <v-chip x-small elevation="0" color="blue white--text" class="font-weight-bold">
                                          {{ item.description }}
                                        </v-chip>
                                      </template>
                                      <template v-else>
                                        <v-chip x-small elevation="0" color="green white--text"
                                                class="font-weight-bold">
                                          {{ item.description }}
                                        </v-chip>
                                      </template>
                                    </template>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </div>
                      </v-form>
                    </div>
                  </div>
                </v-fade-transition>
              </div>
            </div>
          </v-form>
          <div class="font-weight-bold mb-5" v-if="editingTime">
            <template v-if="editingTime.hasOwnProperty('doctor')">
              دکتر انتخاب شده:
              {{ editingTime.doctor.full_name }}
            </template>
          </div>
          <div class="font-weight-bold" v-if="selectedTimeForAssignIds.length !=0">
            زمان‌ انتخاب شده:
          </div>
          <div class="mt-3">
            <template v-if="selectedTimeForAssignIds.length !=0">
              به زمان محلی شما:
              <div class="selectedTime">
                <template>
                  <div class="time-record d-flex justify-space-between"
                       v-for="(time ,i ) in selectedTimeFromAssignObject"
                       :key="i">
                    <div class=" d-flex justify-center flex-column">
                      {{ time.end }} - {{ time.start }}
                    </div>
                    <div>
                      {{ time.day | dateGorgianFilter }}
                      <br>
                      {{ time.day | dateJalaliFilter }}
                    </div>
                  </div>

                </template>
                <template v-if="bulkTime.start_at && attach_duration && session_date">
                  <div class="time-record d-flex justify-space-between">
                    <div class=" d-flex justify-center flex-column">
                      {{ bulkTime.start_at }} - {{ endTimeSessionInAttachTime }}
                    </div>
                    <div>
                      {{ session_date | dateGorgianFilter }}
                      <br>
                      {{ session_date | dateJalaliFilter }}
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <div class="mt-3" v-if="selectedUser != null && selectedTimeForAssignIds.length !=0">
            به زمان محلی
            {{ selectedUser.full_name }}
            -
            <b>
              {{ selectedUser.timezone }}
            </b>
            :
            <div class="selectedTime">
              <template v-if="selectedTimeForAssignIds.length !=0">
                <div class="time-record d-flex justify-space-between" v-for="(time ,i ) in selectedTimeFromAssignObject"
                     :key="i">
                  <div class=" d-flex justify-center flex-column">
                    {{ convertToUserTimezone(time.end) }} - {{ convertToUserTimezone(time.start) }}
                  </div>
                  <div>
                    {{ time.day | dateGorgianFilter }}
                    <br>
                    {{ time.day | dateJalaliFilter }}
                  </div>
                </div>
              </template>
              <template v-if="bulkTime.start_at && attach_duration && session_date">
                <div class="time-record d-flex justify-space-between">
                  <div class=" d-flex justify-center flex-column">
                    {{ bulkTime.start_at }} - {{ endTimeSessionInAttachTime }}
                  </div>
                  <div>
                    {{ session_date | dateGorgianFilter }}
                    <br>
                    {{ session_date | dateJalaliFilter }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <template v-else>
            <template v-if="bulkTime.start_at && selectedUser">
              به زمان محلی
              {{ selectedUser.full_name }}
              -
              <b>
                {{ selectedUser.timezone }}
              </b>
              :
              <div class="time-record d-flex justify-space-between">
                <div class=" d-flex justify-center flex-column">
                  <!--                  {{ convertToUserTimezone(time.end) }}-->
                  {{ convertToUserTimezoneWithCalDuration(bulkTime.start_at, attach_duration) }}
                  - {{ convertToUserTimezone(bulkTime.start_at) }}

                </div>
                <div>
                  {{ session_date | dateGorgianFilter }}
                  <br>
                  {{ session_date | dateJalaliFilter }}
                </div>
              </div>
            </template>
          </template>
          <template v-if="selectedUser != null">
            <div class="font-weight-bold mb-2">
              تغیر زمان محلی کاربر
            </div>
            <ChangeTimeZone :user_id="selectedUser.id" :user_timezone="selectedUser.timezone"
                            @callback="afterUpdateTimeZone"/>
          </template>
          <template v-if="!editingTime">
            <div class="mb-4 font-weight-bold">
              انتخاب دکتر:
            </div>
            <div class="ml-4" v-for="(doctor , i) in preSessionDoctor" :key="i">
              <v-radio-group dense height="20" class="pa ma-0" v-model="selectedDoctor"
                             :readonly="prepareStoreTimePayload().timebooks.length != 0 && deletedTime.length != 0">
                <v-radio class="pa-0 ma-0" :value="doctor.doctor_profile.id"
                         :label="doctor.full_name"></v-radio>
              </v-radio-group>
            </div>
          </template>

        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="addTimeDisableBtn" color="primary" elevation="0" @click="performingSetSession">
            ثبت
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="500px" v-model="editTimeSessionModal">
      <v-card>
        <v-card-title>
          ویرایش زمان جلسه
        </v-card-title>
        <v-card-text>
          <v-form ref="changeTimeModal" v-if="changeSessionTime!=null">
            <v-text-field
                :rules="requiredRules"
                outlined
                dense
                :value="changeSessionTime.session_date"
                label="تاریخ جلسه"
                prepend-icon="event"
                readonly
                @click="session_date_modal = true"
            ></v-text-field>
            <template>

            </template>
            <AdminDatePicker

                @close="session_date_modal=false"
                :dialog="session_date_modal"
                @input="session_date_modal = false"
                v-model="changeSessionTime.session_date"
                :setShamsiDefaultValue="true"

            ></AdminDatePicker>
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field :rules="requiredRules" @click="openClock('start_change_session')" dense outlined
                              v-model="changeSessionTime.start_at" label="زمان شروع"></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field :rules="requiredRules" @click="openClock('end_change_session')" dense outlined
                              v-model="changeSessionTime.end_at"
                              label="زمان پایان"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-btn color="primary" @click="changingSessionTime">
            ثبت
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="600px" v-model="modalSessionToUsedModal">
      <v-card>
        <v-card-title>
          برگزاری جلسات
        </v-card-title>
        <v-card-text>
          جلسات زیر برگزار نشده اند. از بین‌ آن‌ها جلساتی که قصد اعلام برگزاری دارید را انتخاب کنید:
          <div class="sessionsArea">
            <v-data-table
                class="mt-5"
                disable-pagination
                hide-default-footer
                hide-default-header
                :items="sessions_to_used"
                :headers="sessions_to_used"
                dense
                show-select
                v-model="selected_sessions_to_used"
            >

              <template v-slot:item="{item}">
                <tr>
                  <td class="pa-1">
                    <v-checkbox class="pa-0 mt-3 ma-0" :value="item.session.id" dense
                                multiple
                                v-model="selected_sessions_to_used">

                    </v-checkbox>
                  </td>
                  <td class="pa-1">{{ item.start }}</td>
                  <td class="pa-1">{{ item.end }}</td>
                  <td class="pa-1">{{ item.date }} - {{ item.date | dateJalaliFilter }}</td>
                  <td class="pa-1">{{ item.user.full_name }}</td>
                  <!--                                <td class="pa-1">{{ item.end }} - {{ item.start }}</td>-->
                </tr>
              </template>
            </v-data-table>

          </div>
          <v-btn class="mt-5" @click="setSessionsUsed" color="primary" elevation="0"
                 :disabled="selected_sessions_to_used.length == 0">
            ثبت
          </v-btn>
        </v-card-text>

      </v-card>
    </v-dialog>
    <v-dialog width="500px" scrollable v-model="modalReserveTime">
      <v-card min-height="500px">
        <v-card-title>رزرو زمان</v-card-title>
        <v-card-text>
          <div style="position: relative">
            <div class="d-flex  justify-center" style="margin-top: -25px">
              <div class="mt-5">
                نام کاربر
              </div>
              <div class="mx-2">
                <v-switch dense inset v-model="is_user_id"/>
              </div>
              <div class="mt-5">
                کد کاربر
              </div>
            </div>
            <v-text-field :rules="requiredRules" outlined @input="userId = null" dense label="نام مراجع"
                          v-model="username"></v-text-field>
            <div class="userDropDown" v-if="users.length != 0">
              <div class="user-row">
                <div class="user-record" v-for="(user , i) in users" :key="i" @click="selectUser(user)">
                  <div class="d-flex justify-space-between " style="border-bottom: 1px solid #ddd">
                    <div>
                      {{ user.full_name }}
                      -
                      <v-chip v-if="user.leadUser" x-small color="primary pt-1">lead</v-chip>
                      <v-chip x-small v-if="user.newUser" color="red white--text mr-1 pt-1">new</v-chip>
                      <div class="mt-1 ">
                        {{ user.email }}
                      </div>

                    </div>
                    <div>
                      <v-chip color="primary" small v-if="user.notes.length != 0">
                        {{ user.notes[0].description }}
                      </v-chip>
                      <div style="direction: ltr !important;text-align: left;" class="mt-1 ml-1">
                        {{ user.cel }}
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <div class="boxWalletStatus" v-if="selectedUser">
            <div class="font-weight-bold mb-2">
              وضعیت کیف پول کاربر
            </div>
            <div class="d-flex">
              <div class="walletItem">
                <div class="ItemTitle">
                  تعداد جلسات:
                </div>
                <div class="itemValue">
                  {{ selectedUser.wallet.global_packages }}
                </div>
              </div>
              <div class="walletItem mr-4">
                <div class="ItemTitle">
                  مدت زمان کسر/اضافه:
                </div>
                <div class="itemValue">
                  {{ selectedUser.time_wallet.time }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <div>
                <v-btn color="primary" x-small elevation="0" @click="resolveUserSession">
                  تسویه
                </v-btn>
              </div>
            </div>
          </div>

          <v-checkbox class="pa-0 ma-0" v-model="reservedNextTime"
                      v-if="!userDidntRegister ||  !isPreSession"
                      label="رزرو کردن زمان‌های بعدی برای کاربر"></v-checkbox>
          <v-fade-transition>
            <div v-if="reservedNextTime">
              <div class="mb-5">
                <v-form ref="reserved_time">
                  <v-row>
                    <v-col cols="12" lg="5">
                      <v-text-field label="تعداد جلسات" :rules="requiredRules" v-model="reserved_model.count"
                                    dense outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="5">
                      <v-select :items="reserved_units" item-value="key" :rules="requiredRules" item-text="title"
                                label="بازه زمانی" v-model="reserved_model.unit" dense outlined></v-select>
                    </v-col>
                    <v-col cols="12" lg="2">
                      <v-btn color="primary" block class="mt-1" elevation="0" @click="prepareReservedTime">
                        تایید
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div>
                    <!--                          <v-row>-->
                    <!--                            <v-col cols="6" v-for="(item , i) in reserved_prepare_time" :key="i">-->
                    <!--                              <div class="reservedTimeBox">-->
                    <!--                                <div class="d-flex justify-space-between">-->
                    <!--                                  <div>-->
                    <!--                                    {{ item.date | dateJalaliFilter }}-->
                    <!--                                    <br>-->
                    <!--                                    {{ item.date }}-->
                    <!--                                  </div>-->
                    <!--                                  <div class="d-flex flex-column justify-center" style="font-size: 13px">-->
                    <!--                                    {{ item.start }} تا {{ item.end }}-->
                    <!--                                  </div>-->
                    <!--                                </div>-->
                    <!--                              </div>-->
                    <!--                            </v-col>-->
                    <!--                            <v-col cols="6">-->
                    <!--                            </v-col>-->
                    <!--                          </v-row>-->
                    <div class="reserveTimeArea">
                      <v-data-table
                          disable-pagination
                          hide-default-footer
                          hide-default-header
                          :items="reserved_prepare_time"
                          :headers="headers"
                          dense
                          show-select

                      >
                        <!--                            <template v-slot:top>-->
                        <!--                              <v-switch-->
                        <!--                                  v-model="singleSelect"-->
                        <!--                                  label="Single select"-->
                        <!--                                  class="pa-3"-->
                        <!--                              ></v-switch>-->
                        <!--                            </template>-->
                        <template v-slot:header="{ props: { headers } }">
                          <tr>
                            <th class="pa-1">
                              <v-checkbox v-model="selectedAllTime" :value="true" dense multiple></v-checkbox>
                            </th>
                            <th class=" text-center">شمسی</th>
                            <th class=" text-center">میلادی</th>

                            <!--                                <td class=" text-center">{{ item.end }} - {{ item.start }}</td>-->
                            <th class=" text-center">
                              وضعیت
                            </th>
                          </tr>
                        </template>
                        <template v-slot:item="{item}">
                          <tr :style="!item.visibility && 'background: rgba(255,0,0,0.2);color:black'">
                            <td class="pa-1">
                              <v-checkbox :readonly="!item.visibility" class="pa-0 mt-3 ma-0" :value="item" dense
                                          multiple
                                          v-model="selected_reserved_session">
                              </v-checkbox>
                            </td>
                            <td class="pa-1 text-center">{{ item.date | dateJalaliFilter }}</td>
                            <td class="pa-1 text-center">{{ item.date }}</td>

                            <!--                                <td class="pa-1">{{ item.end }} - {{ item.start }}</td>-->
                            <td class="pa-1 text-center">
                              <template v-if="item.confilict_time">
                                <template v-if="item.confilict_time.state == 'off_time'">
                                  <v-chip x-small elevation="0" color="red white--text" class="font-weight-bold">
                                    تعطیل
                                  </v-chip>
                                </template>
                                <template v-else>
                                  <v-chip x-small elevation="0" color="warning white--text"
                                          class="font-weight-bold">
                                    تداخل زمان
                                  </v-chip>
                                </template>
                              </template>
                              <template v-else>
                                <template v-if="item.description.includes('تخصیص')">
                                  <v-chip x-small elevation="0" color="blue white--text" class="font-weight-bold">
                                    {{ item.description }}
                                  </v-chip>
                                </template>
                                <template v-else>
                                  <v-chip x-small elevation="0" color="green white--text"
                                          class="font-weight-bold">
                                    {{ item.description }}
                                  </v-chip>
                                </template>
                              </template>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </v-form>
              </div>
            </div>
          </v-fade-transition>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="reservedTimeForUser">
            ثبت
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SetExtraSessionTimeModal @close="closeModal" :session="targetedSession" @afterSet="afterSet"
                              :show="modalExtraSession"/>
    <v-row class="fill-height" justify="center">
      <v-col cols="12" lg="10" class="fill-height">
        <div class="fill-height">
          <v-dialog v-model="clockModel" width="300">
            <v-time-picker
                v-if="clockModel"
                v-model="ModelClock"
                @input="clockModel = false"
                format="24hr"
            ></v-time-picker>
            <v-btn color="primary" @click="clockModel = false">
              ثبت
            </v-btn>
          </v-dialog>
          <v-dialog width="500" v-model="addDialog">
            <v-card>
              <v-card-title>
                <div class="d-flex justify-space-between flex-grow-1">
                  <v-tabs centered v-model="addType">
                    <v-tab>
                      <p class="font-weight-bold">
                        افزودن تکی
                      </p>
                    </v-tab>
                    <v-tab>
                      <p class="font-weight-bold">
                        افزودن گروهی
                      </p>
                    </v-tab>
                    <v-tab>
                      <p class="font-weight-bold">
                        افزودن ساعت تعطیل
                      </p>
                    </v-tab>
                  </v-tabs>
                </div>
              </v-card-title>
              <v-card-text>
                {{ dateFilter(selectingDay) }}

                <v-fade-transition mode="out-in">
                  <v-tabs-items v-model="addType">
                    <v-tab-item>
                      <div :key="1" class="single_time">
                        <v-form ref="addSingleTime">
                          <div class="d-flex mt-3">
                            <div>
                              <v-text-field clearable outlined :rules="requiredRules" readonly
                                            @click="openClock('start_single')" v-model="time.start_at" dense
                                            label="ساعت شروع"></v-text-field>
                            </div>
                          </div>
                          <div>
                            <div class="d-flex mt-2">
                              <p class="font-weight-bold ml-2">
                                زمان جلسه:
                              </p>
                              <v-chip class="mx-2 " small v-for="(duration , i) in durations" :key="i"
                                      @click="time.duration = duration.key"
                                      :color="time.duration == duration.key && 'primary'">
                                {{ duration.title }}
                              </v-chip>
                              <p>
                                دقیقه
                              </p>
                            </div>
                          </div>
                        </v-form>
                        <template v-if="preSessionDoctor">
                          <div class="mt-4">
                            دکتر‌:
                            <div class="d-flex ">
                              <div class="ml-4" v-for="(doctor , i) in preSessionDoctor" :key="i">
                                <v-radio-group class="pa ma-0" v-model="selectedDoctor"
                                               :readonly="prepareStoreTimePayload().timebooks.length != 0 || deletedTime.length != 0">
                                  <v-radio class="pa-0 ma-0" :value="doctor.doctor_profile.id"
                                           :label="doctor.full_name"></v-radio>
                                </v-radio-group>
                              </div>
                            </div>
                          </div>
                        </template>
                        <div>
                          <v-btn small color="primary" :disabled="addTimeDisableBtn" elevation="0"
                                 @click="addTime(selectingDay)">
                            افزودن
                          </v-btn>
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div :key="2" class="bulk_time">
                        <v-form ref="addBulkTime">
                          <div>
                            <div class="d-flex mt-3">
                              <div>
                                <v-text-field :rules="requiredRules" clearable outlined v-model="bulkTime.start_at"
                                              readonly
                                              @click="openClock('start_bulk')" dense
                                              label="ساعت شروع"></v-text-field>
                              </div>
                              <div>
                                <v-text-field :rules="requiredRules" clearable outlined v-model="bulkTime.end_at" dense
                                              label="ساعت پایان" readonly
                                              class="mr-1"
                                              @click="openClock('end_bulk')"></v-text-field>
                              </div>
                              <v-spacer></v-spacer>
                            </div>

                            <div class="d-flex mt-3">
                              <p class="font-weight-bold ml-2">
                                زمان استراحت:
                              </p>
                              <v-chip class="mx-2" small v-for="(rest , i) in restDuration" :key="i"
                                      @click="bulkTime.rest_duration = rest.key"
                                      :color="bulkTime.rest_duration == rest.key && 'primary'">
                                {{ rest.title }}
                              </v-chip>
                              <p>
                                دقیقه
                              </p>
                            </div>
                            <div class="d-flex mt-2">
                              <p class="font-weight-bold ml-2">
                                زمان جلسه:
                              </p>
                              <v-chip class="mx-2" small v-for="(duration , i) in durations" :key="i"
                                      @click="bulkTime.duration = duration.key"
                                      :color="bulkTime.duration == duration.key && 'primary'">
                                {{ duration.title }}
                              </v-chip>
                              <p>
                                دقیقه
                              </p>
                            </div>
                          </div>
                        </v-form>
                        <template v-if="preSessionDoctor">
                          <div class="mt-4">
                            دکتر‌:
                            <div class="d-flex ">
                              <div class="ml-4" v-for="(doctor , i) in preSessionDoctor" :key="i">
                                <v-radio-group class="pa ma-0" v-model="selectedDoctor"
                                               :readonly="prepareStoreTimePayload().timebooks.length != 0 || deletedTime.length != 0">
                                  <v-radio class="pa-0 ma-0" :value="doctor.doctor_profile.id"
                                           :label="doctor.full_name"></v-radio>
                                </v-radio-group>
                              </div>
                            </div>
                          </div>
                        </template>
                        <v-btn small color="primary" elevation="0" class="mt-5" @click="addBulkTimes(selectingDay)"
                               :disabled="addTimeDisableBtn">
                          افزودن
                        </v-btn>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <v-form ref="offTimeForm">
                        <div class="d-flex mt-3">
                          <div>
                            <v-text-field :rules="requiredRules" clearable outlined v-model="bulkTime.start_at"
                                          readonly
                                          @click="openClock('start_bulk')" dense
                                          label="ساعت شروع"></v-text-field>
                          </div>
                          <div>
                            <v-text-field :rules="requiredRules" clearable outlined v-model="bulkTime.end_at" dense
                                          label="ساعت پایان" readonly
                                          class="mr-1"
                                          @click="openClock('end_bulk')"></v-text-field>
                          </div>
                          <v-spacer></v-spacer>
                        </div>
                        <v-textarea label="توضیحات" outlined dense v-model="descriptionOffTime"></v-textarea>
                      </v-form>
                      <template v-if="preSessionDoctor">
                        <div class="mt-4">
                          دکتر‌:
                          <div class="d-flex ">
                            <div class="ml-4" v-for="(doctor , i) in preSessionDoctor" :key="i">
                              <v-radio-group class="pa ma-0" v-model="selectedDoctor"
                                             :readonly="prepareStoreTimePayload().timebooks.length != 0 || deletedTime.length != 0">
                                <v-radio class="pa-0 ma-0" :value="doctor.doctor_profile.id"
                                         :label="doctor.full_name"></v-radio>
                              </v-radio-group>
                            </div>
                          </div>
                        </div>
                      </template>
                      <v-btn @click="addOffTime(selectingDay)" color="primary" elevation="0"
                             :disabled="addTimeDisableBtn">
                        افزودن ساعت تعطیل
                      </v-btn>

                    </v-tab-item>
                  </v-tabs-items>
                </v-fade-transition>
              </v-card-text>
            </v-card>
          </v-dialog>
          <div class="d-flex fill-height flex-column justify-center ">
            <div class="calenderBox">
              <template v-if="!isPreSession">
                <div class="d-flex" :class="$vuetify.breakpoint.mdAndDown && 'flex-column'">
                  <div class="doctorInfo" v-if="doctor">
                    <div class=" mb-2" :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : 'mr-5'">
                      <div>
                        <img :src="doctor.avatar ||  require('../../assets/MALE.730d6452.svg')"
                             style="border-radius: 50%"
                             :style="!doctor.avatar && 'border:2px solid #39b787'"
                             :width="$vuetify.breakpoint.mdAndUp ? '100px' : '50px'  " alt="">
                        <div class="font-weight-bold "
                             :class="$vuetify.breakpoint.mdAndDown && 'text-center'">
                          {{ doctor.full_name }}
                        </div>
                        <div style="font-size: 11pt" class="mt-2">{{ doctor.timezone }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1  flex-column d-flex justify-center"
                       :class="$vuetify.breakpoint.mdAndUp ? 'mr-10' : 'mr-2' ">
                    <div class="d-flex noteScroll">
                      <div v-for="(note , i) in doctor.userCrmNote" :key="i">
                        <div class="noteBox ml-2">
                          <div class="d-flex justify-space-between">
                            <div class="pt-1">
                              <span class="dateNote">
                               {{ note.created_at | dateFilter }}
                              </span>
                            </div>
                            <div>
                              <span class="accountManagerNote">
                               {{ note.account_manager.full_name }}
                                <template v-if="profile.id == note.account_manager.id ">
                                  <v-btn x-small fab elevation="0" text @click="deleteNote(note.id)">
                                    <v-icon size="15" color="red">mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                              </span>
                            </div>

                          </div>
                          <div class="noteContent" mt-2>
                            {{ note.description }}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </template>
              <template v-else>
                <h3>
                  تقویم دکتران پیش مشاوره
                </h3>
                <div class="d-flex my-4">
                  <div v-for="(doctor , i ) in preSessionDoctor" :key="i" class="doctorBox ml-2"
                       :style="`background: ${doctor.color} `" @click="selectActiveDoctor(doctor.doctor_profile.id)"
                       :class="activeDoctorId == doctor.doctor_profile.id && 'activeDoctor'">
                    {{ doctor.full_name }} - {{ doctor.timezone }}
                  </div>
                </div>

              </template>
              <div class="d-flex " :class="$vuetify.breakpoint.mdAndDown && 'flex-column'">
                <v-btn small @click="toToday" class=" font-weight-bold" :class="$vuetify.breakpoint.mdAndUp && 'mr-2  '"
                       text color="primary">
                  بازگشت به امروز
                </v-btn>
                <v-btn small color="primary" elevation="0" @click="setSessionModal = true"
                       :class="$vuetify.breakpoint.mdAndDown ? 'mt-2 mx-5' : 'mr-1'">
                  تنظیم جلسه
                </v-btn>
                <v-btn small color="blue white--text" :class="$vuetify.breakpoint.mdAndDown ? 'mt-4 mx-5' : 'mr-2'"
                       elevation="0"
                       v-if="!isPreSession"
                       @click="copyWeekForNextWeek">
                  انتقال زمان‌ها به هفته بعد
                </v-btn>
                <v-btn small color="green white--text" :class="$vuetify.breakpoint.mdAndDown ? 'mt-4 mx-5' : 'mr-2'"
                       elevation="0"
                       v-if="!isPreSession"
                       @click="openModalUsedSessions">
                  برگزاری همه جلسات
                </v-btn>
                <v-spacer></v-spacer>
                <div class="">
                  <div class="" :class="$vuetify.breakpoint.mdAndDown && 'd-flex justify-center my-5'">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" small @click="pervWeek" text>
                          <img src="../../assets/icons/next.png" width="15px" alt="">
                        </v-btn>
                      </template>
                      <span>هفته قبل </span>
                    </v-tooltip>
                    <v-chip class="mx-2">
                      <span :key="i" v-for="(month , i) in monthGorgian">
                        <span class="mx-1" v-if="i == 1">-</span>
                        {{ month }}
                      </span>
                    </v-chip>
                    <v-chip>
                      <span :key="i" v-for="(month , i) in monthJalali">
                         <span class="mx-1" v-if="i == 1">-</span>
                         {{ months[month] }}
                      </span>
                    </v-chip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" small @click="nextWeek" text>
                          <img src="../../assets/icons/next.png" style="transform: rotate(-180deg)" width="15px" alt="">
                        </v-btn>
                      </template>
                      <span>هفته بعدی </span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
              <div class="timeBookArea">
                <div class="d-flex justify-space-between">
                  <div v-for="(day , i ) in weekDays" :key="i" class="day-row flex-grow-1"
                  >
                    <div class="todayBadge">
                      <v-chip v-if="isToday(day)" x-small color="primary">
                        امروز
                      </v-chip>
                    </div>
                    <!--                                        <div class="dayMenu">-->
                    <!--                                          -->
                    <!--                                        </div>-->
                    <div class="labelArea">
                      <div class="d-flex justify-space-between">
                        <div>
                          {{ weeksDayLabel[i] }}

                        </div>
                        <div class="dates">
                          {{ day | dateGorgianFilter }}
                          <br>
                          {{ day | dateJalaliFilter }}
                        </div>
                      </div>
                    </div>
                    <div class="timeArea" @click="openModalAddTime(day)">
                      <v-fade-transition group mode="out-in">
                        <div v-for="(time , i ) in getTimesForDay(day)" :key="i"

                        >
                          <div
                              @click.stop="!time.user && time.state != 'off_time' && time.state != 'offline_assigned' && time.state != 'reserved' && !time.editing && attachTimeToAssign(time,day)"
                              class="timeBlock d-flex flex-column justify-space-between py-1 "
                              :class="computeTimeClass(time)"
                              v-if="canShowTime(time)">
                            <div class="">
                              <template v-if="time.session && time.session.reason == 'required_session_time'">
                                <v-chip x-small color="primary">
                                  جلسه تسویه بدهی
                                </v-chip>
                              </template>
                              <template v-else>
                                {{ time.end }} - {{ time.start }}
                              </template>
                            </div>
                            <div v-if="time.hasOwnProperty('doctor')" class="doctorNameOnTime"
                                 :style="`background: ${time.doctor.color}`">
                              {{ time.doctor.name }}
                            </div>
                            <div v-if="time.user">
                              <div @click="copyText(time.user.id)" style="cursor: pointer">
                                <!--                                <v-chip style="cursor: pointer" x-small color="green lighten-4">-->
                                <template v-if="time.session.reason != 'required_session_time'">
                                  {{ time.user.id }}
                                </template>
<!--                                <template v-if="time.session">-->
<!--                                  {{ time.session.extra_duration }}-->
<!--                                </template>-->
                                <!--                                </v-chip>-->
                              </div>
                              <router-link :to="{name:'clientDetail',params:{clientId:time.user.id}}"
                                           style="text-decoration: none">
                                <div v-if="time.user.full_name != ''">{{ time.user.full_name }}</div>
                                <div v-else>{{ time.user.email }}</div>
                              </router-link>
                              <span class="lead-user primary" v-if="time.user.is_lead_user">Lead</span>
                              <div v-if="time.session && time.session.watsapp_msgs" class="mt-1">
                                <div
                                    style="width: 15px;height: 15px;border-radius: 50%;line-height: 20px"
                                    :style="`position: absolute;left: ${i * 14}px;bottom: -7px`"
                                    class="white" v-for="(message , i) in time.session.watsapp_msgs"
                                    :key="i">
                                  <v-icon size="10" :color="getMessageColor(message.status)">
                                    {{ getMessageIcon(message.status) }}
                                  </v-icon>
                                </div>

                              </div>
                            </div>
                            <div v-if="time.state == 'offline_assigned'">
                              {{ time.description }}
                            </div>
                            <div v-if="time.state == 'reserved'" class="mb-1">
                              <div @click="copyText(time.reserved_user.id)">
                                {{ time.reserved_user.id }}
                              </div>
                              {{ time.reserved_user.full_name }}
                              <span class="lead-user primary" v-if="time.state == 'reserved'">رزرو</span>

                            </div>
                            <div v-if="time.state == 'off_time'">
                              تعطیل
                            </div>
                            <div class="infoTime" v-if="time.state == 'off_time'">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                      color="white"
                                      small
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                  >
                                    mdi-information
                                  </v-icon>
                                </template>
                                <span>{{ time.description }}</span>
                              </v-tooltip>
                            </div>
                            <div class="deleteBtn pt-2 fill-height "
                                 @click.stop="time.hasOwnProperty('id') ? storeIdInDeleteArray(time.id) : deleteTempTime(day,time)">
                              <v-icon small>mdi-close</v-icon>
                            </div>
                            <div class="moreBtn pt-2 fill-height">
                              <v-menu
                                  :close-on-content-click="close_on_content_click"
                                  offset-y
                                  origin="left left"
                                  transition="slide-y-transition"
                                  bottom
                                  style="width: 500px!important;"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon size="20" v-bind="attrs"
                                          v-on="on">mdi-dots-vertical
                                  </v-icon>
                                </template>
                                <v-list class="pa-0" style="width: 150px!important;">
                                  <v-list-item class=""
                                               v-if="time.state != 'reserved' && time.session && time.session.state == 'date_fixed'"
                                               @click="openModalChangeSessionTime(time,day)">
                                    <v-list-item-title class="pa-0">
                                      <div class="menuItem ">
                                        ویرایش زمان جلسه
                                      </div>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item class=""
                                               v-if="time.state != 'reserved' && !time.session && !isPreSession"
                                               @click="openModalReserveTime(time,day)">
                                    <v-list-item-title class="pa-0">
                                      <div class="menuItem ">
                                        رزرو زمان
                                      </div>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item class="" v-if="time.state == 'reserved'"
                                               @click="deleteReserveTime(time,day)">
                                    <v-list-item-title class="pa-0">
                                      <div class="menuItem ">
                                        لغو رزرو
                                      </div>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item class="" v-if="time.state == 'reserved'"
                                               @click="setSessionForReservedTime(time,day)">
                                    <v-list-item-title class="pa-0">
                                      <div class="menuItem ">
                                        تنظیم جلسه
                                      </div>
                                    </v-list-item-title>
                                  </v-list-item>
                                  <template v-if="time.session">
                                    <v-list-item v-if="time.session.state != 'used' && time.session.state != 'canceled'"
                                                 @click="changeStateSession(time.session.id,'used',time)">
                                      <v-list-item-title>
                                        <div class="menuItem">
                                          جلسه برگزار شد
                                        </div>
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if=" time.session && time.session.extra_used == 'not_used'"
                                        @click="openModalExtraSession(time.session)">
                                      <v-list-item-title>
                                        <div class="menuItem">
                                          اعلام کسر/اضافه
                                        </div>
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="time.session.state != 'used' && time.session.state != 'canceled'"
                                                 @click="sendAgainNotify(time.session.id)">
                                      <v-list-item-title>
                                        <div class="menuItem">
                                          ارسال مجدد یادآوری
                                        </div>
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="time.session.state != 'canceled'"
                                                 @click="getConfirmForSessionFailed(time.session.id,time)">
                                      <v-list-item-title>
                                        <div class="menuItem">
                                          جلسه برگزار نشد
                                        </div>
                                        <v-fade-transition>
                                          <div class="pa-3" v-if="sessionFailed.session_id == time.session.id">
                                            <v-btn text color="green" fab x-small @click="changeStateSessionFailed()">
                                              <v-icon>mdi-check</v-icon>
                                            </v-btn>
                                            <v-btn fab text color="red" x-small class="mr-2"
                                                   @click.stop="sessionFailed.session_id = null">
                                              <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                          </div>
                                        </v-fade-transition>
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="getConfirmForDeleteSession(time.session.id,time)">
                                      <v-list-item-title>
                                        <div class="menuItem red--text">
                                          حذف جلسه
                                        </div>
                                        <v-fade-transition>
                                          <div class="pa-3" v-if="sessionDelete.session_id == time.session.id">
                                            <v-btn text color="green" fab x-small @click="deleteSession()">
                                              <v-icon>mdi-check</v-icon>
                                            </v-btn>
                                            <v-btn fab text color="red" x-small class="mr-2"
                                                   @click.stop="sessionDelete.session_id = null">
                                              <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                          </div>
                                        </v-fade-transition>
                                      </v-list-item-title>
                                    </v-list-item>
                                  </template>
                                </v-list>
                              </v-menu>
                            </div>
                          </div>
                        </div>

                      </v-fade-transition>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-left mt-10" style="margin-bottom: 200px">

                <template
                    v-if=" $vuetify.breakpoint.lgAndUp">
                  <v-fade-transition>
                    <v-btn v-if="prepareStoreTimePayload().timebooks.length != 0 || deletedTime.length != 0"
                           :disabled="addTimeDisableBtn" color="#55d3d2" class="white--text" elevation="0"
                           @click="saveChanges">
                      <b>
                        ذخیره تغیرات
                      </b>
                    </v-btn>
                  </v-fade-transition>
                </template>
                <template v-else>
                  <v-fade-transition>
                    <v-bottom-navigation fixed
                                         v-if="prepareStoreTimePayload().timebooks.length != 0 || deletedTime.length != 0">
                      <v-btn block color="#55d3d2" class="white--text" elevation="0" @click="saveChanges">
                        <b>
                          ذخیره تغیرات
                        </b>
                      </v-btn>
                    </v-bottom-navigation>
                  </v-fade-transition>
                </template>

              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/*eslint-disable*/
import moment from 'moment';
import AdminDatePicker from '@/components/AdminDatePicker';

import momentJalali from "moment-jalaali";
import {EventBus} from "@/event-bus";
import {mapGetters} from "vuex";
import ChangeTimeZone from "@/components/calendar/changeTimeZone";
import doctorTimes from "@/components/clients/DoctorTimes";
import loginComponentHome from "@/components/LoginComponentHome";
import Temp from "@/views/Temp";
import SetExtraSessionTimeModal from "@/components/sessions/SetExtraSessionTimeModal";


// import moment as momentG from "moment";

export default {
  components: {SetExtraSessionTimeModal, Temp, ChangeTimeZone, AdminDatePicker},
  watch: {
    showIndebitModal(val) {
      if (!val) {
        this.tempReserveData = null
      }
    },
    '$route'() {
      this.getDoctorTimes()
      this.prepareDateWeek(moment().clone().startOf('week'))
      location.reload()
    },
    setSessionModal(val) {
      if (!val) {
        this.editingTime = false
        this.selectedTimeForAssignIds = []
        this.reserved_prepare_time = []
        this.reservedNextTime = false
        this.reserved_model = {
          count: 1,
          unit: 'every_week'
        }
        this.selected_reserved_session = []

      }
    },
    modalReserveTime(val) {
      if (!val) {
        this.editingTime = false
        this.selectedTimeForAssignIds = []
        this.reserved_prepare_time = []
        this.reservedNextTime = false
        this.reserved_model = {
          count: 1,
          unit: 'every_week'
        }
        this.selected_reserved_session = []
        this.username = null
        this.userId = null
        this.is_user_id = false
        this.selectedAllTime = false
      }
    },
    is_user_id() {
      this.username = null
      this.users = []
    },
    username(val) {
      if (val) {
        clearTimeout(this.usernameTimer)
        this.searchUser(val)
      }
    },
    userDidntRegister() {
      this.users = []
    },
    '$route.params.userId'() {

    },
    selectedAllTime(val) {
      if (this.selectedAllTime.length != 0) {
        this.reserved_prepare_time.forEach((item) => {
          if (item.visibility) {
            this.selected_reserved_session.push(item)
          }
        })
      } else {
        this.selected_reserved_session = []
      }
    }
  },
  data() {
    return {
      modalExtraSession: false,
      targetedSession: null,
      selectedAllTime: false,
      selected_reserved_session: [],
      sessions_to_used: [],
      modalSessionToUsedModal: false,
      selected_sessions_to_used: [],
      headers: [
        {
          text: 'تاریخ شمسی',
          align: 'center',
          sortable: false,
          value: 'jalali',
        },
        {
          text: 'تاریخ شمسی',
          align: 'center',
          sortable: false,
          value: 'gorgian',
        },
        {
          text: 'زمان',
          align: 'center',
          sortable: false,
          value: 'time',
        },
      ],
      sesseionUsedHeaders: [
        {
          text: 'شروع',
          align: 'start',
          sortable: false,
          value: 'jalali',
        },
        {
          text: 'پایین',
          align: 'start',
          sortable: false,
          value: 'gorgian',
        },
        {
          text: 'تاریخ',
          align: 'start',
          sortable: false,
          value: 'time',
        },
      ],
      reserved_model: {
        count: 1,
        unit: 'every_week'
      },
      reserved_units: [
        {
          title: 'هر هفته',
          key: 'every_week',
        },
        {
          title: 'هر ۲ هفته',
          key: 'every_two_week',
        },
        {
          title: 'هر 3 هفته',
          key: 'every_three_week',
        },
        {
          title: 'هر ماه',
          key: 'every_month',
        },

      ],
      selectedTimeForReserve: null,
      addTimeDisableBtn: false,
      selectedDoctor: null,
      activeDoctorId: null,
      preSessionDoctorIDs: [76, 217],
      isPreSession: false,
      preSessionDoctor: [],
      is_user_id: false,
      sessionFailed: {
        session_id: null,
      },
      sessionDelete: {
        session_id: null,
      },
      rederedTime: {},
      close_on_content_click: true,
      changeSessionTime: null,
      sessionToChangeTime: null,
      editTimeSessionModal: false,
      descriptionOffTime: null,
      userIndebt: false,
      useUserCreditor: false,
      userDidntRegister: false,
      reservedNextTime: false,
      session_date: null,
      session_date_modal: false,
      usernameTimer: null,
      clockModel: false,
      setSessionModal: false,
      addType: 0,
      users: [],
      username: null,
      months: {
        Farvardin: 'فروردین',
        Ordibehesht: 'اردیبهشت',
        Khordaad: "خرداد",
        Tir: 'تیر',
        Amordaad: "مرداد",
        Shahrivar: "شهریور",
        Mehr: "مهر",
        Aabaan: "آبان",
        Aazar: "آذر",
        Dey: "دی",
        Bahman: "بهمن",
        Esfand: "اسفند",
      },
      attach_duration: null,
      weekDays: [],
      clockState: null,
      weeksDayLabel: [
        'شنبه',
        'یک‌شنبه',
        'دو‌شنبه',
        'سه‌شنبه',
        'چهارشنبه',
        'پنج‌شنبه',
        'جمعه'
      ],
      monthGorgian: [],
      monthJalali: [],
      doctor: null,
      addDialog: false,
      time: {
        start_at: null,
        duration: 45,
      },
      bulkTime: {
        start_at: null,
        duration: 45,
        end_at: null,
        rest_duration: 15,
      },
      selectingDay: null,
      durations: [
        {
          key: "15",
          title: "15"
        },
        {
          key: "30",
          title: "30"
        },
        {
          key: "45",
          title: "45"
        },
        {
          key: "60",
          title: "60"
        },
        {
          key: "90",
          title: "90"
        }
      ],
      restDuration: [
        {
          key: "0",
          title: "0"
        },
        {
          key: "5",
          title: "5"
        },
        {
          key: "10",
          title: "10"
        },
        {
          key: "15",
          title: "15"
        },
        {
          key: "20",
          title: "20"
        },
        {
          key: "30",
          title: "30"
        },
        {
          key: "40",
          title: "40"
        }
      ],
      times: [],
      nextWeekCounter: 0,
      prevWeekCounter: 0,
      defaultMoment: null,
      selectedUser: null,
      // defaultMoment: (()=>{
      //   console.log(moment().startOf('isoWeek').day(6))
      //   return moment().startOf('isoWeek').day(6)
      // } )(),
      deletedTime: [],
      selectedTimeForAssignIds: [],
      selectedTimeFromAssignObject: [],
      userId: null,
      editingTime: null,
      isCopy: false,
      reserved_prepare_time: [],
      faild_reserved_time: [],
      showIndebitModal: false,
      tempReserveData: null,
      modalReserveTime: false,
    }
  },
  computed: {
    ...mapGetters({
      'profile': 'getUserInfo'
    }),
    endTimeSessionInAttachTime() {
      return moment(this.session_date + " " + this.bulkTime.start_at).add(this.attach_duration, 'minutes').format('HH:mm')
    },
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
    ModelClock: {
      get() {
        if (this.clockState == 'start_single') {
          return this.time.start_at
        } else if (this.clockState == 'start_bulk') {
          return this.bulkTime.start_at
        } else if (this.clockState == 'end_bulk') {
          return this.bulkTime.end_at
        } else if (this.clockState == 'start_change_session') {
          return this.changeSessionTime.start_at
        } else if (this.clockState == 'end_change_session') {
          return this.changeSessionTime.end_at
        }
      },
      set(value) {
        if (this.clockState == 'start_single') {
          this.time.start_at = value;
        } else if (this.clockState == 'start_bulk') {
          this.bulkTime.start_at = value
        } else if (this.clockState == 'end_bulk') {
          this.bulkTime.end_at = value
        } else if (this.clockState == 'start_change_session') {
          this.changeSessionTime.start_at = value
        } else if (this.clockState == 'end_change_session') {
          this.changeSessionTime.end_at = value
        }
      }
    }
  },
  methods: {
    resolveUserSession() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('resolveUserDebt', {user_id: this.selectedUser.id}).then((resp) => {
        let option = {
          type: 'patient',
          loadNotes: true,
          user_id: this.selectedUser.id
        }
        this.$store.dispatch('getClientsListCalendar', {
          page: 1,
          options: option
        }).then((resp) => {
          EventBus.$emit('notify', 'green', undefined, 'تسویه با کاربر انجام شد');
          this.selectedUser = resp.data.users[0]
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }).catch(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    afterSet() {
      this.getDoctorTimes()
    },
    openModalExtraSession(session) {
      this.targetedSession = session
      this.modalExtraSession = true
      this.targetedSession.doctor = {
        id: this.$route.params.counselorId
      }
    },
    closeModal() {
      this.modalExtraSession = false
    },
    copyText(text) {
      navigator.clipboard.writeText(text)
          .then(() => {
            EventBus.$emit('notify', 'green', undefined, 'کد کاربر کپی شد ' + text);
          })
          .catch(err => {
            console.error('Error copying text to clipboard: ', err);
          });
    },
    sendAgainNotify(session_id) {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('sendSessionNotifyWhatsapp', {session_id}).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'یادآوری ارسال شد.');
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    openModalReserveTime(time, day) {
      this.selectedTimeForReserve = time
      this.selectedTimeForReserve.date = day.format('YYYY-MM-DD')
      this.selectedTimeForReserve.day = day
      this.modalReserveTime = true
    },
    reservedTimeForUser() {
      if (this.selected_reserved_session.findIndex(item => item.time_id == this.selectedTimeForReserve.id) == -1) {
        this.selected_reserved_session.push({
          start: this.selectedTimeForReserve.start,
          end: this.selectedTimeForReserve.end,
          date: this.selectedTimeForReserve.date,
          time_id: this.selectedTimeForReserve.id,
          description: "",
        })
      }

      let payload = {
        times: this.selected_reserved_session,
        user_id: this.userId,
        doctor_id: this.$route.params.counselorId,
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('setReservedTime', payload).then((resp) => {
        this.getDoctorTimes()
        this.selectedTimeForAssignIds = []
        this.modalReserveTime = false
        this.$refs.setSessionForm.reset()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    setSessionsUsed() {
      this.$store.dispatch('adminSessionBulkChangeState', {
        'session_ids': this.selected_sessions_to_used
      }).then(() => {
        this.modalSessionToUsedModal = false
        EventBus.$emit('notify', 'green', undefined, 'جلسات با موفقت برگزار شد');
        this.getDoctorTimes()
      })
    },
    openModalUsedSessions() {
      this.sessions_to_used = []
      this.selected_sessions_to_used = []
      this.times.forEach((item) => {
        item.periods.forEach((time) => {
          if (time.session != null && time.session.state == 'date_fixed' && moment(item.date).isBefore(moment())) {
            time.date = item.date
            this.sessions_to_used.push(time)
            this.selected_sessions_to_used.push(time.session.id)
          }
        })
      })
      if (this.sessions_to_used.length != 0) {
        this.modalSessionToUsedModal = true
      } else {
        EventBus.$emit('notify', 'green', undefined, 'همه جلسات برگزار شده اند.');
      }
      // this.session
    },
    prepareReservedTime() {
      if (this.$refs.reserved_time.validate()) {
        this.selected_reserved_session = []
        this.makeTimeForReserve()
      }
    },
    deleteNote(id) {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('deleteNote', id).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به حذف شد');
        this.getDoctorTimes()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    canShowTime(time) {
      if (!this.activeDoctorId)
        return true
      return this.activeDoctorId == time.doctor.id
    },
    selectActiveDoctor(id) {
      if (this.activeDoctorId == id) {
        this.activeDoctorId = null
      } else {
        this.activeDoctorId = id
      }
    },
    afterUpdateTimeZone(new_tz) {
      this.selectedUser.timezone = new_tz
    },
    searchUser(val) {
      if (!this.userDidntRegister) {
        if (this.userId != null) {
          return;
        }
        this.usernameTimer = setTimeout(() => {
          if (val) {
            EventBus.$emit('setLoading', true)
            let option = {
              type: 'patient',
              loadNotes: true,
            }
            if (this.is_user_id) {
              option.user_id = this.username
            } else {
              option.patient_name = this.username
              option.assigned_counselor = this.$route.params.counselorId
            }

            this.$store.dispatch('getClientsListCalendar', {
              page: 1,
              options: option
            }).then((resp) => {
              this.users = resp.data.users
            }).finally(() => {
              EventBus.$emit('setLoading', false)
            })
          } else {
            this.users = []
          }
        }, 1000)
      }
    },
    getMessageTooltip(message) {
      switch (message.template) {
        case 'session_set':
          return 'SET'
        case 'pre_session_set':
          return 'SET'
        case 'xmin_session_reminder':
          return 'REMIND'
        default :
          return 'S'
      }
    },
    getMessageColor(status) {
      return status === 'read' ? 'green' : status == 'failed' ? 'red' : 'grey'
    },
    getMessageIcon(status) {
      return status === 'sent' ? 'mdi-check' : 'mdi-check-all'
    },
    getConfirmForSessionFailed(session_id, time) {
      this.close_on_content_click = false
      this.sessionFailed.session_id = session_id
      this.editingTime = time
    },
    getConfirmForDeleteSession(session_id, time) {
      this.close_on_content_click = false
      this.sessionDelete.session_id = session_id
      this.editingTime = time
    },
    deleteSession() {
      this.close_on_content_click = true
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('adminResetSession', this.sessionDelete.session_id).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'حذف جلسه انجام شد.');
        this.getDoctorTimes()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    changeStateSessionFailed() {
      this.close_on_content_click = true
      if (this.sessionFailed.session_id) {
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('CancelSession', {session_id: this.sessionFailed.session_id}).then(() => {
          this.getDoctorTimes()
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    },
    changeStateSession(session_id, state, time) {

      this.editingTime = time

      EventBus.$emit('setLoading', true)
      let options = {
        session: session_id,
        state: state,
        doctorId: this.getDoctorId(),
        datetime: null,
      };
      this.$store.dispatch('adminSessionChangeState', options).then(() => {
        this.getDoctorTimes()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    changingSessionTime() {
      if (this.$refs.changeTimeModal.validate()) {

        let payload = {
          'from': this.changeSessionTime.session_date + " " + this.changeSessionTime.start_at,
          'to': this.changeSessionTime.session_date + " " + this.changeSessionTime.end_at,
          'session_id': this.changeSessionTime.session_id,
        }

        this.$store.dispatch('ChangeSessionDateTime', payload).then(() => {
          EventBus.$emit('notify', 'green', undefined, 'تغیر زمان جلسه انجام شد.');
          this.getDoctorTimes()

        }).catch(() => {
          this.editTimeSessionModal = false
          EventBus.$emit('notify', 'red', undefined, 'تغیر زمان جلسه انجام با مشکل مواجه شد.');

        }).finally(() => {
          this.editTimeSessionModal = false

        })

      }
    },
    openModalChangeSessionTime(time, day) {
      this.changeSessionTime = null;
      this.editingTime = time
      this.$nextTick().then(() => {

        this.changeSessionTime = {
          start_at: time.start,
          end_at: time.end,
          session_date: moment(day).format('YYYY-MM-DD'),
          session_id: time.session.id
        }
        this.sessionToChangeTime = time
        this.editTimeSessionModal = true

      })
    },
    convertToUserTimezoneWithCalDuration(time, duration) {
      let originalTime = moment.tz(moment(new Date()).format('yyyy-MM-DD') + `T${time}:00`, this.profile.tz); // Replace with your original time and timezone
      var convertedTime = originalTime.clone().tz(this.selectedUser.timezone); // Replace "Asia/Tokyo" with the target timezone
      var formattedTime = convertedTime.add(duration, 'm').format("HH:mm"); // Replace the format as needed
      return formattedTime
    },
    computeTimeClass(time) {
      if (time.session && time.session.reason == 'required_session_time') {
        return 'indebtTime';
      } else if (time.editing) {
        return 'editableTime';
      } else if (this.timeIsInDeleteArray(time.id)) {
        return 'deleteAbleTime';
      } else if (time.state == 'reserved') {
        return 'reservedBox';
      }
      if (time.state == 'off_time') {
        return 'offTime'
      } else if (time.state == 'offline_assigned') {
        return 'offline_assigned_time'
      } else if (this.timeIsInAttachArray(time.id)) {
        return 'selectedForAttach';
      } else if (!time.user) {
        return 'timeBox';
      }
      if (time.hasOwnProperty('session') && time.session.state == 'used') {
        return 'timeBoxWithUser sessionUsed';
      } else if (time.hasOwnProperty('session') && time.session.state == 'canceled') {
        return 'timeBoxWithUser sessionCancel ';
      } else {
        return 'timeBoxWithUser';
      }
    },
    addOffTime(day) {
      this.addTimeDisableBtn = true
      if (this.$refs.offTimeForm.validate()) {
        if (this.$route.name == 'preSessionCalendar' && !this.selectedDoctor) {
          EventBus.$emit('notify', 'red', undefined, 'لطفا یک مشاور را انتخاب کنید  ');
          return;
        }
        this.insertDayIndexToTimes(day)
        this.attachTimeToDay(day, this.bulkTime.start_at, this.bulkTime.end_at, true, true,)
        this.$refs.offTimeForm.reset()
        this.time = {
          duration: null,
        }
        this.addDialog = false
      }
      this.addTimeDisableBtn = false
    },
    convertToUserTimezone(time) {
      let originalTime = moment.tz(moment(new Date()).format('yyyy-MM-DD') + `T${time}:00`, this.profile.tz); // Replace with your original time and timezone
      var convertedTime = originalTime.clone().tz(this.selectedUser.timezone); // Replace "Asia/Tokyo" with the target timezone
      var formattedTime = convertedTime.format("HH:mm"); // Replace the format as needed
      return formattedTime
    },
    selectUser(user) {
      if (user.full_name != " ") {
        this.username = user.full_name
      } else {
        this.username = user.email
      }
      this.userId = user.id
      this.selectedUser = user
      // console.log(user)
      this.users = []
      clearTimeout(this.usernameTimer)
    },
    attachTimeToAssign(time, day) {
      this.selectedTimeForReserve = time
      let index = this.selectedTimeForAssignIds.findIndex(item => item == time.id)
      if (index == -1) {
        this.selectedUser = null
        this.username = null
        this.selectedTimeForAssignIds = []
        this.selectedTimeFromAssignObject = []
        time.day = day
        this.selectedTimeForAssignIds.push(time.id)
        this.selectedTimeFromAssignObject.push(time)
        this.setSessionModal = true
        this.editingTime = time

      } else {
        this.selectedTimeForAssignIds.splice(index, 1);
      }


      // let index = this.selectedTimeForAssignIds.findIndex(item => item == time.id)
      // if (index == -1) {
      //   this.selectedTimeForAssignIds.push(time.id)
      //   time.day = day
      //   this.selectedTimeFromAssignObject.push(time)
      // } else {
      //   this.selectedTimeForAssignIds.splice(index, 1);
      //   let indexObject = this.selectedTimeFromAssignObject.findIndex(item => item.id == time.id)
      //   this.selectedTimeFromAssignObject.splice(indexObject, 1);
      // }
      // console.log(this.selectedTimeFromAssignObject, '2121')
    },
    getDoctorId() {
      if (this.$route.params.counselorId) {
        return this.$route.params.counselorId
      } else if (this.editingTime) {
        return this.editingTime.doctor.id
      } else if (this.selectedDoctor) {
        return this.selectedDoctor
      }
    },
    setSessionForReservedTime(time, day) {
      let payload = {
        user_id: time.reserved_user.id,
        doctor_id: this.$route.params.counselorId,
        indebt: false,
        datetime_start: null,
        datetime_end: null,
        datetime_id: time.id,
        offline_assign: false,
        description: null
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('setSessionTimeCalendar', payload).then(() => {
        this.getDoctorTimes()
        EventBus.$emit('notify', 'green', undefined, 'جلسه با موفقیت ایجاد شد');
      }).catch((er) => {
        this.tempReserveData = payload;
        EventBus.$emit('setLoading', false)
        if (er.response.data.data.status == 1001) {
          this.showIndebitModal = true
          EventBus.$emit('notify', 'red', undefined, 'کاربر پکیج کافی برای برگزاری جلسه ندارد');
        }
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    indebitUserAndReservetime() {
      this.tempReserveData.indebt = true
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('setSessionTimeCalendar', this.tempReserveData).then(() => {
        this.getDoctorTimes()
        EventBus.$emit('notify', 'green', undefined, 'جلسه با موفقیت ایجاد شد');
        this.tempReserveData = null
      }).finally(() => {
        this.showIndebitModal = false
        EventBus.$emit('setLoading', false)
      })
    },
    deleteReserveTime(time, day) {
      let payload = {
        doctor_id: this.$route.params.counselorId,
        time_id: time.id
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('deleteReservation', payload).then(() => {
        this.getDoctorTimes()
        EventBus.$emit('notify', 'green', undefined, 'وضعیت زمان تغیر پیدا کرد');
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    performingSetSession() {
      if (this.$refs.setSessionForm.validate()) {
        let payload = {
          user_id: this.userId,
          doctor_id: this.getDoctorId(),
          indebt: this.userIndebt,
          datetime_start: null,
          datetime_end: null,
          datetime_id: null,
          offline_assign: this.userDidntRegister,
          description: this.username,
          requiresExtraSession: this.useUserCreditor,
        }
        if (this.selectedTimeForAssignIds.length != 0) {
          payload.datetime_id = this.selectedTimeForAssignIds[0]
        } else {
          if (!this.attach_duration) {
            EventBus.$emit('notify', 'red', undefined, 'مدت زمان جلسه را انتخاب کنید.');
            return;
          }
          payload.datetime_start = moment(this.session_date + " " + this.bulkTime.start_at).format('YYYY-MM-DD HH:mm:ss')
          payload.datetime_end = moment(this.session_date + " " + this.bulkTime.start_at).add(this.attach_duration, 'minutes').format('YYYY-MM-DD HH:mm:ss')
        }

        EventBus.$emit('setLoading', true)

        this.addTimeDisableBtn = true
        this.$store.dispatch('setSessionTimeCalendar', payload).then((resp) => {

              if (this.selected_reserved_session.length != 0 && !this.userDidntRegister) {
                let payload = {
                  times: this.selected_reserved_session,
                  user_id: this.userId,
                  doctor_id: this.$route.params.counselorId,
                }
                this.$store.dispatch('setReservedTime', payload).then((resp) => {
                  this.getDoctorTimes()
                  this.selectedTimeForAssignIds = []
                  this.setSessionModal = false
                  this.$refs.setSessionForm.reset()
                })
              } else {
                this.getDoctorTimes()
                this.selectedTimeForAssignIds = []
                this.setSessionModal = false
                this.$refs.setSessionForm.reset()

              }


              EventBus.$emit('notify', 'green', undefined, resp.message);
              this.selectedUser = null
            }
        ).catch((er) => {
          if (er.response.data.data.status == 1001) {
            EventBus.$emit('notify', 'red', undefined, 'کاربر پکیج کافی برای برگزاری جلسه ندارد');
          } else if (er.response.data.data.status == 1002) {
            EventBus.$emit('notify', 'red', undefined, 'کاربر طلبکار نمی باشد');
          }
        }).finally(() => {
          this.userDidntRegister = false;
          if (this.selected_reserved_session.length == 0) {
            EventBus.$emit('setLoading', false)
          }
          this.addTimeDisableBtn = false
        })

      }
    },
    deleteTempTime(day, time) {
      let date = this.times.find((item) => item.date == day.format("YYYY/MM/DD"))
      date.find
      const index = date.periods.findIndex(item => {
        return (
            item.start === time.start &&
            item.end === time.end &&
            item.editing === time.editing
        );
      });
      date.periods.splice(index, 1)
    }
    ,
    openClock(state) {
      this.clockState = state
      this.clockModel = true
    }
    ,
    storeIdInDeleteArray(id) {
      if (this.deletedTime.findIndex(item => item == id) == -1) {
        this.deletedTime.push(id)
      } else {
        const index = this.deletedTime.indexOf(id);
        if (index !== -1) {
          this.deletedTime.splice(index, 1);
        }
      }
      // console.log(this.deletedTime, '1212')
    }
    ,
    timeIsInDeleteArray(id) {
      return this.deletedTime.includes(id)
    }
    ,
    timeIsInAttachArray(id) {
      return this.selectedTimeForAssignIds.includes(id)
    }
    ,
    saveChanges() {
      this.addTimeDisableBtn = true
      this.destroyTimes().then(() => {
        this.storeTimes().finally(() => {
          this.getDoctorTimes()
          this.addTimeDisableBtn = false
        })
      })
    }
    ,
    destroyTimes() {
      if (!this.isPreSession) {
        return new Promise((resolve, reject) => {
          if (this.deletedTime.length != 0) {
            this.$store.dispatch('adminCounselorRemoveTimes', {
              id: this.$route.params.counselorId,
              payload: {times: this.deletedTime}
            }).then((resp) => {
              // this.getDoctorTimes()
              this.deletedTime = []
              return resolve(resp)
            }).catch((er) => {
              return reject(er)
            })
          } else {
            return resolve(true)
          }
        })
      } else {
        return new Promise((resolve, reject) => {
          if (this.deletedTime.length != 0) {
            this.$store.dispatch('adminCounselorsRemoveTimes', {
              payload: {times: this.deletedTime}
            }).then((resp) => {
              this.deletedTime = []
              return resolve(resp)
            }).catch((er) => {
              return reject(er)
            })
          } else {
            return resolve(true)
          }
        })

      }
    }
    ,
    storeTimes() {
      let payload = this.prepareStoreTimePayload()
      return new Promise((resolve, reject) => {
        if (payload.timebooks.length != 0) {
          return this.$store.dispatch('saveCounselorTimebooks', payload).then((resp) => {
            return resolve(resp)
          }).catch((er) => {
            return reject(er)
          })
        } else {
          return resolve(true)
        }
      })

    }
    ,
    prepareStoreTimePayload() {
      let times = JSON.parse(JSON.stringify(this.times))
      times.forEach(date => {
        date.periods = date.periods.filter(item => item.editing === true)
      });
      times = times.filter(item => item.periods.length !== 0)
      return {
        "timebooks": times,
        id: this.getDoctorId()
      }
    }
    ,
    addBulkTimes(day) {
      if (this.$refs.addBulkTime.validate() && this.bulkTime.duration && this.bulkTime.rest_duration) {
        this.addTimeDisableBtn = true
        if (this.$route.name == 'preSessionCalendar' && !this.selectedDoctor) {
          EventBus.$emit('notify', 'red', undefined, 'لطفا یک مشاور را انتخاب کنید  ');
          return;
        }
        this.insertDayIndexToTimes(day)
        let start = moment(this.bulkTime.start_at, 'HH:mm')
        let end = moment(this.bulkTime.end_at, 'HH:mm');
        while (start.isBefore(end)) {
          this.attachTimeToDay(day, start.format("HH:mm"), this.bulkTime.duration)
          start = start.add(this.bulkTime.duration, 'minutes').add(this.bulkTime.rest_duration, "minutes")
        }
        this.$refs.addBulkTime.reset()
        this.prepareDefaultBulkTime()

        this.addDialog = false
        this.addTimeDisableBtn = false
      }
    }
    ,
    toToday() {
      // this.defaultMoment = moment().clone().startOf('week').subtract(1, 'day')
      // this.defaultMoment = moment().startOf('isoWeek').day(6)
      this.resetMoment()
      this.prepareDateWeek(this.defaultMoment)
    }
    ,
    openModalAddTime(day) {
      this.selectingDay = day
      this.addDialog = true
    }
    ,
    insertDayIndexToTimes(day) {
      let date = moment(day).format('YYYY/MM/DD');
      if (this.times.findIndex(item => item.date == date) == -1) {
        this.times.push({
          date: date,
          periods: []
        },)
      }
    }
    ,
    addTime(day) {
      this.addTimeDisableBtn = true

      if (this.$refs.addSingleTime.validate() && this.time.duration) {
        if (this.$route.name == 'preSessionCalendar' && !this.selectedDoctor) {
          EventBus.$emit('notify', 'red', undefined, 'لطفا یک مشاور را انتخاب کنید  ');
          return;
        }
        this.insertDayIndexToTimes(day)
        this.attachTimeToDay(day, this.time.start_at, this.time.duration, false)
        this.$refs.addSingleTime.reset()
        this.time = {
          // start_at: null,
          duration: 45,
        }
        this.addDialog = false
      }
      this.addTimeDisableBtn = false
    }
    ,
    canAddTime(dateTimes, start, duration, date, fromOff = false, haveEnd = false) {
      //
      // return {
      //   can: true ,
      //   off_time: [],
      // }

      let response = true

      let confilictTime = null
      //convert to moment time, start of new time
      const start_inserted_event = moment(`${date instanceof moment ? date.format('YYYY-MM-DD') : date} ${start}`)

      let end_inserted_event;
      if (fromOff) {
        end_inserted_event = moment(`${date.format('YYY-MM-DD')} ${duration}`)
      } else {
        if (!haveEnd) {

          end_inserted_event = this.prepareTimeByDuration(dateTimes.date, start_inserted_event.format('HH:mm'), duration, true)


        } else {

          end_inserted_event = moment(`${date} ${duration}`)

        }
        // end_inserted_event = this.test(this.prepareTimeByDuration(start, duration))
      }


      const today = moment(date).format("YYYY-MM-DD")

      let times = []

      if (this.$route.name == 'preSessionCalendar') {

        times = dateTimes.periods.filter(item => {
          if (item.hasOwnProperty('doctor') && item.doctor.id === this.selectedDoctor) {
            return true;
          } else {
            return false;
          }
        });

        // console.log(this.selectedDoctor)
      } else {
        // times = JSON.parse(JSON.stringify(dateTimes.periods))
        times = dateTimes.periods
        let availableTimes = []
        times.forEach(time => {
          const session = time.session || {};
          if (session.reason !== 'required_session_time') {
            availableTimes.push(time);  // or the appropriate key for the time
          }
        });
        times = availableTimes
        console.log(times, '121212')

      }
      if (times.length != 0) {

        times.forEach(item => {

          // let start = moment(start, "HH:ss")
          // let item_start = moment(item.start)

          const start_exist_event = moment(`${dateTimes.date} ${item.start}`, 'YYYY/MM/DD HH:mm');
          const end_exist_event = moment(`${dateTimes.date} ${item.end}`, 'YYYY/MM/DD HH:mm');


          if (fromOff) {
            if (moment(`${today} ${start_inserted_event.format("HH:mm")}`).isBetween(`${today} ${start_exist_event.format("HH:mm")}`, `${today} ${end_exist_event.format("HH:mm")}`) || moment(`${today} ${end_inserted_event.format("HH:mm")}`).isBetween(`${today} ${start_exist_event.format("HH:mm")}`, `${today} ${end_exist_event.format("HH:mm")}`)) {
              response = false
              confilictTime = item
            } else if (moment(`${today} ${start_exist_event.format("HH:mm")}`).isBetween(`${today} ${start_inserted_event.format("HH:mm")}`, `${today} ${end_inserted_event.format("HH:mm")}`) || moment(`${today} ${end_exist_event.format("HH:mm")}`).isBetween(`${today} ${start_inserted_event.format("HH:mm")}`, `${today} ${end_inserted_event.format("HH:mm")}`)) {
              response = false
              confilictTime = item
            } else if (start_inserted_event.isSame(start_exist_event) || end_inserted_event.isSame(start_exist_event)) {
              response = false
              confilictTime = item
            }
          } else {

            // console.log(`${today} ${start_inserted_event.format("HH:mm")}`)
            let newEventStart = moment(`${today} ${start_inserted_event.format("HH:mm")}`);
            let newEventEnd = moment(`${today} ${end_inserted_event.format("HH:mm")}`);
            let startOfExistingTime = `${today} ${start_exist_event.format("HH:mm")}`;
            let endOfExistingTime = `${today} ${end_exist_event.format("HH:mm")}`;
            let startOfExistingTimeMoment = moment(startOfExistingTime);
            let endOfExistingTimeMoment = moment(endOfExistingTime);

            // console.log({
            //   newEventStart:newEventStart.format('YYYY-MM-DD HH:mm'),
            //   newEventEnd:newEventEnd.format('YYYY-MM-DD HH:mm'),
            //   startOfExistingTime,
            //   endOfExistingTime,
            // })

            if (newEventStart.isBetween(startOfExistingTime, endOfExistingTime, null, '()') || newEventEnd.isBetween(startOfExistingTime, endOfExistingTime, null, '()')) {
              response = false
              confilictTime = item

            } else if (startOfExistingTimeMoment.isBetween(newEventStart, newEventEnd, null, '()') || endOfExistingTimeMoment.isBetween(newEventStart, newEventEnd, null, '()')) {
              response = false
              confilictTime = item

            } else if (newEventStart.isSame(startOfExistingTime) && newEventEnd.isSame(endOfExistingTime)) {
              response = false
              confilictTime = item;

            } else {
              if (this.isCopy) {

                if (start_exist_event.isSameOrBefore(start_inserted_event) && end_exist_event.isSameOrAfter(end_inserted_event)) {
                  // console.log(start_exist_event.format('HH:mm'), end_exist_event.format('HH:mm'), start_inserted_event.format('HH:mm'), end_inserted_event.format('HH:mm'), start_exist_event.isSameOrBefore(start_inserted_event), end_exist_event.isSameOrAfter(end_inserted_event))
                  response = false
                  confilictTime = item
                }

              } else {

                if (start_inserted_event.isSameOrBefore(start_exist_event) && end_inserted_event.isSameOrAfter(end_exist_event)) {
                  // console.log({
                  //   start_inserted_event: start_inserted_event.format('YYYY-MM-DD HH:mm'),
                  //   start_exist_event: start_exist_event.format('YYYY-MM-DD HH:mm'),
                  //   end_inserted_event: end_inserted_event.format('YYYY-MM-DD HH:mm'),
                  //   end_exist_event: end_exist_event.format('YYYY-MM-DD HH:mm'),
                  // })
                  response = false
                  item.date = dateTimes.date
                  confilictTime = item

                  // console.log(start_inserted_event.isSameOrBefore(start_exist_event),end_inserted_event.isSameOrAfter(end_exist_event))

                }
              }
            }


          }


          // }
        })
      }
      // return response
      return {
        can: response,
        off_time: confilictTime,
      }
    },
    attachTimeToDay(day, start, duration, haveEnd = false, offTime = false) {
      let date = moment(day).format('YYYY/MM/DD');
      let dateTimes = this.times.find(item => item.date == date)
      let index = this.times.findIndex(item => item.date == date)
      let can_add_time = this.canAddTime(dateTimes, start, duration, day, offTime, haveEnd).can
      if (can_add_time) {
        this.times[index].periods.push({
          start: start,
          end: haveEnd ? duration : this.prepareTimeByDuration(dateTimes.date, start, duration),
          editing: true,
          off_time: offTime,
          description: this.descriptionOffTime
        })
        return true
      } else {
        EventBus.$emit('notify', 'red', undefined, ` زمان  ${start + "  -  " + (haveEnd ? duration : this.prepareTimeByDuration(dateTimes.date, start, duration))} با دیگر زمان ها تداخل دارد `);
        return false
      }
    }
    ,
    test(time) {
      return moment(time, 'HH:mm');
    }
    ,
    prepareTimeByDuration(date, time, duration, instance = false) {
      const t = moment(`${date} ${time}`, 'YYYY/MM/DD HH:mm');
      t.add(duration, 'minutes');
      if (instance) {
        return t
      }
      return t.format('HH:mm');
    },
    isToday(date) {
      return moment(date).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD")
    }
    ,
    prepareDateWeek(moment) {
      this.weekDays = []
      const startOfWeek = moment
      for (let i = 0; i < 7; i++) {
        const day = startOfWeek.clone().add(i, 'day');
        this.weekDays.push(day);
      }
      this.prepareMonthLabel()
    }
    ,
    prepareMonthLabel() {
      this.monthJalali = []
      this.monthGorgian = []
      this.weekDays.map(day => {
        if (this.monthGorgian.findIndex(item => item == day.format('MMMM')) == -1) {
          this.monthGorgian.push(day.format('MMMM'))
        }
        if (this.monthJalali.findIndex(item => item == momentJalali(day).format("jMMMM")) == -1) {
          this.monthJalali.push(momentJalali(day).format("jMMMM"))
        }
      });
    }
    ,
    nextWeek() {
      this.rederedTime = {}
      this.defaultMoment.add(1, 'week')
      this.prepareDateWeek(this.defaultMoment)
    }
    ,
    pervWeek() {
      this.rederedTime = {}
      this.defaultMoment.subtract(1, 'week')
      this.prepareDateWeek(this.defaultMoment)
    }
    ,
    getTimesForDay(day) {
      let date = moment(day).format('YYYY/MM/DD');
      let times = []
      this.times.map(item => {
        if (item.date == date) {
          times = item.periods
        }
      })
      this.rederedTime[date] = times
      return times
    }
    ,
    copyWeekForNextWeek() {
      let AllCounter = 0
      let successCounter = 0
      this.isCopy = true
      Object.keys(this.rederedTime).forEach(item => {
        let date = moment(item).add('7', 'days').format('YYYY-MM-DD')
        this.insertDayIndexToTimes(date)
        this.rederedTime[item].forEach((time) => {
          AllCounter++
          let t = this.attachTimeToDay(date, time.start, time.end, true)
          if (t) {
            successCounter++;
          }
        })
      })
      if (AllCounter == successCounter) {
        EventBus.$emit('notify', 'green', undefined, `زمان ها به هفته بعد منتقل شد.`);
      } else {
        EventBus.$emit('notify', 'green', undefined, `  از ${AllCounter} زمان موجود ${successCounter} زمان به هفته بعد منتقل شد  `);
      }
      this.isCopy = false
      // this.rederedTime.forEach((index, time) => {
      //   console.log(index,ti)
      // })

    }
    ,
    getDoctorTimeSigular() {
      this.$store.dispatch('getCounselorProfile', {userid: this.$route.params.counselorId}).then((resp) => {
        this.times = []
        this.$nextTick().then(() => {
          this.times = resp.data.doctor.timebooks
          // console.log(this.times)
          this.doctor = resp.data.doctor
          this.prepareDefaultBulkTime()
        })
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      });
    }
    ,
    getDoctorTimeBulk() {
      this.$store.dispatch('getPreSessionTime').then((resp) => {
        this.$nextTick().then(() => {
          // this.times = resp.data.doctor.timebooks
          this.times = resp.data.times

          // let doctor = resp.data.doctor

          // times.forEach((item) => {
          //
          //   item.periods.forEach((time) => {
          //     time.doctor = doctor
          //     // console.log(time,'2112')
          //   })
          //
          //   let index = this.times.findIndex(i => i.date == item.date)
          //   if (index != -1) {
          //     this.times[index].periods.push(...item.periods)
          //   } else {
          //     this.times.push(item)
          //   }
          // })
          // this.preSessionDoctor.push(resp.data.doctor)
          this.prepareDefaultBulkTime()
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      })

      // EventBus.$emit('setLoading', false)

    }
    ,
    getDoctorTimes() {
      EventBus.$emit('setLoading', true)
      if (this.isPreSession) {
        this.getDoctorTimeBulk()
      } else {
        this.getDoctorTimeSigular()
      }
    }
    ,
    getDataPreSessionDoctor() {
      this.preSessionDoctor = []
      this.$store.dispatch('getPreSessionAgents').then((resp) => {
        this.preSessionDoctor.push(...resp.data.agents)
      })
    }
    ,
    prepareDefaultBulkTime() {
      if (this.isPreSession) {
        this.bulkTime.rest_duration = "15"
        this.bulkTime.duration = "15"
      } else {
        this.bulkTime.rest_duration = this.doctor.doctor_profile.rest_time
        this.bulkTime.duration = this.doctor.doctor_profile.session_time
      }
    }
    ,
    dateFilter(val) {
      return moment(val).format("YYYY/MM/DD") + " - " + momentJalali(val).format("jYYYY/jMM/jDD")
    }
    ,
    resetMoment() {
      moment.updateLocale(moment.locale(), {
        week: {
          dow: 6, // Saturday
        },
      });
      this.defaultMoment = moment().clone().startOf('week')
    },
    makeTimeForReserve() {
      this.reserved_prepare_time = []
      let session_count = this.reserved_model.count;
      let action = 'every_week'

      // let next_date = this.prepareNextTimeInReserved(moment(this.selectedTimeForReserve.day), this.reserved_model.unit)
      let new_params = this.prepareNextTimeInReserved(this.reserved_model.unit)

      let next_date = moment(this.selectedTimeForReserve.day).add(new_params.count, new_params.unit)
      while (session_count != 0) {
        let index = this.times.findIndex((item) => item.date == next_date.format('YYYY/MM/DD'));
        if (index == -1) {
          this.reserved_prepare_time.push({
            start: this.selectedTimeForReserve.start,
            end: this.selectedTimeForReserve.end,
            date: next_date.format('YYYY-MM-DD'),
            visibility: true,
            description: 'ساخت زمان'
          })
          session_count -= 1
        } else {
          let exac_time = this.times[index].periods.find((item) => item.start == this.selectedTimeForReserve.start && item.end == this.selectedTimeForReserve.end)
          if (exac_time && exac_time.user == null && exac_time.state != 'reserved') {
            this.reserved_prepare_time.push({
              start: this.selectedTimeForReserve.start,
              end: this.selectedTimeForReserve.end,
              date: next_date.format('YYYY-MM-DD'),
              time_id: exac_time.id,
              visibility: true,
              description: 'تخصیص زمان'
            })
            session_count -= 1
          } else {
            let condition = this.canAddTime(this.times[index], this.selectedTimeForReserve.start, this.selectedTimeForReserve.end, next_date.format('YYYY-MM-DD'), false, true)
            let visibility = condition.can
            this.reserved_prepare_time.push({
              start: this.selectedTimeForReserve.start,
              end: this.selectedTimeForReserve.end,
              date: next_date.format('YYYY-MM-DD'),
              confilict_time: condition.off_time,
              visibility,
              description: visibility ? 'ساخت زمان' : 'تداخل زمان'
            })
            if (visibility) {
              session_count -= 1
            }
          }
        }
        next_date = moment(next_date).add(new_params.count, new_params.unit)
        // next_date = this.prepareNextTimeInReserved(moment(next_date), this.reserved_model.unit)
      }


      // this.selectedTimeForReserve
    },
    prepareNextTimeInReserved(unit) {
      switch (unit) {
        case 'every_week':
          return {
            count: 1,
            unit: 'week',
          }
        case 'every_two_week':
          return {
            count: 2,
            unit: 'weeks',
          }
        case 'every_three_week':
          return {
            count: 3,
            unit: 'weeks',
          }
        case 'every_month':
          return {
            count: 1,
            unit: 'month',
          }
      }
    }
  },
  created() {


    this.resetMoment()
    // this
  },
  mounted() {
    if (this.$route.name == 'preSessionCalendar') {
      this.isPreSession = true
      this.getDataPreSessionDoctor()
    } else {
      this.isPreSession = false
    }
    this.getDoctorTimes()
    this.prepareDateWeek(moment().clone().startOf('week'))
    if (this.$route.query.userId) {
      this.is_user_id = true
      this.$nextTick().then(() => {
        this.username = this.$route.query.userId
        this.searchUser(this.username)
      })

      // this.is_user_id = true
      // this.username = this.$route.query.userId
      // this.searchUser(this.$route.query.userId)
    }
  },
  filters: {
    dateGorgianFilter(date) {
      if (typeof date == 'string') {
        date = moment(date)
      }
      if (date) {
        return date.format('YYYY/MM/DD')
      }
    },
    dateJalaliFilter(date) {
      return momentJalali(date).format('jYYYY/jMM/jDD')
    },
    dateFilter(val) {
      if (val) {
        return momentJalali(val).format('jYYYY/jMM/jDD HH:mm')
      }
    }
  },
}
</script>

<style scoped>

@media (min-width: 200px) and (max-width: 800px) {
  .timeBookArea {
    width: 90% !important;
    overflow: scroll;
    margin: 0px auto 100px auto;
  }

  .day-row {
    width: 150px !important;
  }

  .calenderBox {
    height: 100% !important;
  }

  .doctorNameOnTime {
    left: -10px !important;
  }

  .noteScroll {
    overflow-x: auto !important;
  }
}

.boxWalletStatus {
  border: 1px solid #dddddd;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
}

.walletItem {
  display: flex;
}

.ItemTitle {
  color: grey;
}

.itemValue {
  margin-right: 1px;
  font-weight: bolder;
}

.sessionsArea {
  max-height: 250px;
  overflow-y: auto;
}

.reserveTimeArea {
  max-height: 300px;
  overflow-y: auto;
}

.noteScroll {
  width: 100%;

}

.reservedTimeBox {
  border: 1px solid #dddddd;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
  border-right: 3px solid grey;
}

.doctorNameOnTime {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, .2);
  left: 3px;
  top: -5px;
  background: #ddd;
  color: black;
  border-radius: 5px;
  width: 25px;
  font-size: 8px;
  overflow: hidden;
  height: 15px;
}

.doctorBox {
  padding: 5px;
  border-radius: 5px;
  border: 4px solid rgba(0, 0, 0, .2);
  transition: all 0.3s;
  cursor: pointer;
}

.activeDoctor {
  border: 4px solid #439F47;
  transition: all 0.3s;
}

.time-record {
  border: 1px solid #ededed;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.userDropDown {
  background: white;
  border: 2px solid #ededed;
  border-radius: 0px 0px 10px 10px;
  padding: 5px;
  max-height: 150px;
  position: absolute;
  top: 100px;
  right: 0px;
  left: 0px;
  overflow-y: auto;
  z-index: 10;
}

.user-record {
  margin: 5px 0px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.user-record:hover {
  background: #ededed;
  transition: all 0.3s;
}


.dates {
  font-size: 10px;
  text-align: left;
  color: #8290ac;
  font-weight: bolder;
}

.calenderBox {
  height: 90%;
}

.calendarArea {
  background: #e0e9f1;
}

/*::-webkit-scrollbar {*/
/*  width: 2px;*/
/*}*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*  background: transparent;*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*  background: #000;*/
/*}*/

/*!* Handle on hover *!*/
/*::-webkit-scrollbar-thumb:hover {*/
/*  background: #555;*/
/*}*/

.day-row {
  padding: 5px;
  background: white;
  margin: 5px;
  border: 1px solid #f6f7f9;
  border-radius: 10px;
  position: relative;
}

.deactiveDay-row {
  background: #ededed;
  opacity: 0.7;
}

.todayBadge {
  position: absolute;
  bottom: 0px;
  z-index: 10;
}

.dayMenu {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.labelArea {
  padding: 5px;
  width: 100%;
  border-bottom: 1px solid #dddddd;
}

.timeArea {
  width: 100%;
  height: 450px;
  overflow-y: auto;
  margin-top: 20px;
  overflow-x: hidden !important;


  /*background: #ddd;*/
  border-radius: 0px 0px 10px 10px;
  padding: 5px;

}

.today {
  border: 1px solid #55d3d2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /*border-radius: 20px;*/
}

.timeBox {
  background: #f6f7fa;
  border-left: 6px solid #55d3d2;
  cursor: pointer;
  color: #1e263f;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
  position: relative;
  transition: all 0.3s;
}


.reservedBox {
  background: rgba(22, 234, 221, 0.4);
  border-left: 6px solid RGB(22 234 221);
  cursor: pointer;

  color: #1e263f;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
  position: relative;
  transition: all 0.3s;
}


.reservedBox:hover .deleteBtn {
  display: block;
  transition: all 0.3s;
}

.reservedBox:hover .moreBtn {
  display: block;
}

.timeBox:hover .moreBtn {
  display: block;
}


.timeBoxWithUser {
  background: #f6f7fa;
  border-left: 6px solid #439F47;
  color: #1e263f;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
  position: relative;
  transition: all 0.3s;
}

.sessionUsed {
  background: #85f38a;
  border-left: 6px solid #439F47;
  color: #1e263f;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
  position: relative;
  transition: all 0.3s;
}

.timeBlock {
  min-height: 45px;
}

.sessionCancel {
  background: #ffad00;
  border-left: 6px solid #ff7735;
  color: #1e263f;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
  position: relative;
  transition: all 0.3s;
}

.timeBoxWithUser:hover .moreBtn {
  display: block;
}

.deleteBtn {
  position: absolute;
  right: 10px;
  display: none;
  transition: all 0.3s;
  cursor: pointer;
}

.moreBtn {
  position: absolute;
  left: 10px;
  display: none;
  transition: all 0.3s;
  cursor: pointer;
}

.infoTime {
  position: absolute;
  left: 10px;
  margin-top: 8px;
  transition: all 0.3s;
  cursor: pointer;
}

.editableTime:hover .deleteBtn {
  display: block;
  transition: all 0.3s;
}

.timeBox:hover .deleteBtn {
  display: block;
  transition: all 0.3s;
}

.timeBoxWithUser:hover .deleteBtn {
  display: block;
  transition: all 0.3s;
}

.editableTime {
  background: #f6f7fa;
  position: relative;
  border-left: 6px solid #ffa935;
  color: #1e263f;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
}

.indebtTime {
  background: #00FFFF;
  color: black;
  position: relative;
  border-left: 6px solid blue;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
}

.selectedForAttach {
  background: blue;
  position: relative;
  border-left: 6px solid #55d3d2 !important;
  color: white;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
  transition: all 0.3s;
}

.deleteAbleTime .deleteBtn {
  display: block;
  /*transition: all 0.3s;*/
}

.deleteAbleTime {
  background: #f6f7fa;
  position: relative;
  border-left: 6px solid red;
  color: #1e263f;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
}

.offTime {
  background: #f36e6e;
  color: white;
  position: relative;
  border-left: 6px solid red;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
}

.offTime:hover .deleteBtn {
  display: block;
  /*transition: all 0.3s;*/
}


.offline_assigned_time {
  background: rgba(177, 3, 252, 0.2);
  color: #1e263f;
  position: relative;
  border-left: 6px solid #b103fc;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 0px 5px 5px 0px;
}

.offline_assigned_time:hover .deleteBtn {
  display: block;
  /*transition: all 0.3s;*/
}

.lead-user {
  display: inline-block;
  position: absolute;
  right: 0;
  color: white;
  /*background-color: blue;*/
  padding: 2px 1px 0 1px;
  width: 30px;
  bottom: 0;
  border-radius: 5px;
  line-height: 9px;
}

.noteBox {
  border: 1px solid #dddddd;
  padding: 10px;
  border-radius: 20px;
  min-width: 250px;
  background: white;
}

.accountManagerNote {
  font-size: 11px;
}

.dateNote {
  font-size: 11px;
}


.noteContent {
  border-top: 1px solid #dddddd;
  margin-top: 5px;
  padding-top: 5px;
}
</style>